// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.header-title {
    margin: 50px auto;
}`, "",{"version":3,"sources":["webpack://./src/style/Header.css"],"names":[],"mappings":"AAAA;IACI,MAAM;IACN,WAAW;IACX,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".header-container {\r\n    top: 0;\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.header-title {\r\n    margin: 50px auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contenedor-entrada {
    justify-content: center;
    width: 60%;
    margin: auto;
}

.header-entrada-container {
    margin: auto;
    justify-content: left;
}

.header-entrada {
    cursor: pointer;
    margin-top: 50px;
    display: inline-block;
}

.header-entrada-icono {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .contenedor-entrada {
        justify-content: center;
        width: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/style/Carta.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI;QACI,uBAAuB;QACvB,UAAU;IACd;AACJ","sourcesContent":[".contenedor-entrada {\r\n    justify-content: center;\r\n    width: 60%;\r\n    margin: auto;\r\n}\r\n\r\n.header-entrada-container {\r\n    margin: auto;\r\n    justify-content: left;\r\n}\r\n\r\n.header-entrada {\r\n    cursor: pointer;\r\n    margin-top: 50px;\r\n    display: inline-block;\r\n}\r\n\r\n.header-entrada-icono {\r\n    display: inline-block;\r\n    margin-left: 10px;\r\n    cursor: pointer;\r\n}\r\n\r\n@media screen and (max-width: 1000px) {\r\n    .contenedor-entrada {\r\n        justify-content: center;\r\n        width: 90%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background-color: white;
    width: 30%;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 10px;
    z-index: 10;
}

.tooltip-header {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.tooltip-header-close-icono {
    position: absolute;
    right: 0;
    top: 10px;
    cursor: pointer;
}

.alergeno-tooltip-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin-bottom: 5px;
}

.alergeno-tooltip {
    width: 30px;
    height: 30px;
    display: inline-block;
    justify-content: center;
}

.alergeno-tooltip-text {
    display: inline-block;
    margin: auto 10px;
    vertical-align: middle;
}

@media screen and (max-width: 1000px) {
    .tooltip {
        width: 60%;
    }
}`, "",{"version":3,"sources":["webpack://./src/style/Tooltip.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,uBAAuB;IACvB,UAAU;IACV,oCAAoC;IACpC,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".tooltip {\r\n    position: fixed;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    padding: 10px;\r\n    background-color: white;\r\n    width: 30%;\r\n    border: 1px solid rgb(216, 216, 216);\r\n    border-radius: 10px;\r\n    z-index: 10;\r\n}\r\n\r\n.tooltip-header {\r\n    font-size: 20px;\r\n    font-weight: bold;\r\n    text-align: center;\r\n}\r\n\r\n.tooltip-header-close-icono {\r\n    position: absolute;\r\n    right: 0;\r\n    top: 10px;\r\n    cursor: pointer;\r\n}\r\n\r\n.alergeno-tooltip-container {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: left;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.alergeno-tooltip {\r\n    width: 30px;\r\n    height: 30px;\r\n    display: inline-block;\r\n    justify-content: center;\r\n}\r\n\r\n.alergeno-tooltip-text {\r\n    display: inline-block;\r\n    margin: auto 10px;\r\n    vertical-align: middle;\r\n}\r\n\r\n@media screen and (max-width: 1000px) {\r\n    .tooltip {\r\n        width: 60%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buscador-de-platos-contenedor {
    display: flex;
    justify-content: center;
    margin: auto;
    height: 30px;
}

.buscador-entrada {
    border-radius: 7px;
    border: 1px solid #707070;
    height: 30px;
    margin-top: -2px;
    width: 500px;
    padding-left: 10px;
}

.buscador-entrada::placeholder {
    color: #ABABAB;
}

.buscador-entrada:focus {
    outline: none;
}

.buscador-boton {
    cursor: pointer;
    border: none;
    background-color: transparent;
    max-height: max-content;
    margin-left: -33px;
}

.buscador-icono {
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .buscador-entrada {
        width: 80%;
    }
}`, "",{"version":3,"sources":["webpack://./src/style/Buscador.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,6BAA6B;IAC7B,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".buscador-de-platos-contenedor {\r\n    display: flex;\r\n    justify-content: center;\r\n    margin: auto;\r\n    height: 30px;\r\n}\r\n\r\n.buscador-entrada {\r\n    border-radius: 7px;\r\n    border: 1px solid #707070;\r\n    height: 30px;\r\n    margin-top: -2px;\r\n    width: 500px;\r\n    padding-left: 10px;\r\n}\r\n\r\n.buscador-entrada::placeholder {\r\n    color: #ABABAB;\r\n}\r\n\r\n.buscador-entrada:focus {\r\n    outline: none;\r\n}\r\n\r\n.buscador-boton {\r\n    cursor: pointer;\r\n    border: none;\r\n    background-color: transparent;\r\n    max-height: max-content;\r\n    margin-left: -33px;\r\n}\r\n\r\n.buscador-icono {\r\n    cursor: pointer;\r\n}\r\n\r\n@media screen and (max-width: 600px) {\r\n    .buscador-entrada {\r\n        width: 80%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

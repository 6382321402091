// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alergenos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 30px;
}

.alergenos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 95%;
    max-width: 600px;
}

.button-container {
    display: inline-block;
    justify-content: center;
    cursor: pointer;
}

.boton-alergeno {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 35px;
    height: 35px;
    padding: 0%;
    display: inline-block;
    justify-content: center;
    width: 0 100px;
    vertical-align: middle;
}

.alergeno-tag {
    display: inline-block;
    margin: auto 10px;
    vertical-align: middle;
}

.boton-alergeno>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/style/Alergenos.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,WAAW;IACX,YAAY;IACZ,WAAW;IACX,qBAAqB;IACrB,uBAAuB;IACvB,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".alergenos {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n    margin-bottom: 30px;\r\n    margin-top: 30px;\r\n}\r\n\r\n.alergenos-container {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n    width: 95%;\r\n    max-width: 600px;\r\n}\r\n\r\n.button-container {\r\n    display: inline-block;\r\n    justify-content: center;\r\n    cursor: pointer;\r\n}\r\n\r\n.boton-alergeno {\r\n    background-color: transparent;\r\n    border: none;\r\n    cursor: pointer;\r\n    width: 35px;\r\n    height: 35px;\r\n    padding: 0%;\r\n    display: inline-block;\r\n    justify-content: center;\r\n    width: 0 100px;\r\n    vertical-align: middle;\r\n}\r\n\r\n.alergeno-tag {\r\n    display: inline-block;\r\n    margin: auto 10px;\r\n    vertical-align: middle;\r\n}\r\n\r\n.boton-alergeno>img {\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: cover;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entrada {
    display: flex;
}

.entrada-nombre {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 0;
}

.entrada-derecha {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: 20px;
}

.entrada-precio {
    margin-left: 20px;
}
.entrada-alergeno {
    margin-top: 0;
    margin-left: 7px;
    padding-top: 0;
}

.plato-alergenos {
    width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/style/Entrada.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".entrada {\r\n    display: flex;\r\n}\r\n\r\n.entrada-nombre {\r\n    text-decoration: underline;\r\n    cursor: pointer;\r\n    margin-left: 10px;\r\n    margin-bottom: 0;\r\n}\r\n\r\n.entrada-derecha {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-left: auto;\r\n    margin-right: 20px;\r\n}\r\n\r\n.entrada-precio {\r\n    margin-left: 20px;\r\n}\r\n.entrada-alergeno {\r\n    margin-top: 0;\r\n    margin-left: 7px;\r\n    padding-top: 0;\r\n}\r\n\r\n.plato-alergenos {\r\n    width: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
